// $primary: #53D2FA;
// Override Kendo UI Drawer background color
$kendo-drawer-bg: #052c65;
// $kendo-drawer-item-selected-bg: #052c65;
$kendo-drawer-text: white;
// $kendo-drawer-item-hover-bg: #052c65;
// $kendo-picker-hover-bg: red;
$kendo-picker-outline-hover-bg: none;
$kendo-picker-outline-hover-text: none;
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";

$kendo-drawer-bg: "black";

// override kendo-ui
.k-input-solid:focus,
.k-input-solid.k-focus {
  box-shadow: 0 0 0 0.1rem
    color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 25%, transparent);
}

.k-button-solid-primary:focus,
.k-button-solid-primary.k-focus {
  box-shadow: 0 0 0px 0.1rem
    color-mix(in srgb, var(--kendo-color-primary, #0d6efd) 50%, transparent);
}
