@import "./themes/kendo-theme-custom.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link-button {
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.resend-code-container {
  display: flex;
  justify-content: flex-end;
}

.resend-code-link {
  color: #007bff;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
