.custom-toolbar {
  width: 100%;
  background-color: white;
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 1px;
  padding: 3px 8px;
  color: #656565;
}

.ms-auto {
  margin-left: auto;
}

.content {
  background-color: #f6f8ff;
}

.popup-content {
  padding: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 120px;
  overflow: hidden;
  margin-right: 10px;
}

.popup-menu {
  display: flex;
  flex-direction: column;
}

.popup-item {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.2s ease;
}

.popup-item:hover {
  background-color: #f1f1f1;
}

.popup-item:first-child {
  border-bottom: 1px solid #ddd;
}
